import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { How, Baner } from "page_components/friends"
import { Content, File } from "page_components/subpages"

const Friends = ({ location }) => {
  const title = "Mieszkaj z przyjaciółmi!"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header={title}
        description="Wyobraź sobie życie w sąsiedztwie swoich znajomych lub rodziny – wspólne chwile, wsparcie na co dzień, a do tego... dodatkowe korzyści!"
        background={
          require("assets/images/friends/friends-header-image.jpg").default
        }
      />
      <Content
        header="Dołącz do naszego Programu Poleceń i spraw, by Twoi bliscy zamieszkali obok Ciebie."
        description="Na polecaniu Trust Investment zyskują wszystkie strony. Twoi znajomi zamieszkają w świetnym miejscu, zaprojektowanym w przemyślany sposób, pełnym udogodnień w komfortowej lokalizacji oraz otrzymają rabat na zakup mieszkania. Ty zyskasz wdzięczność na lata oraz gratyfikację finansową."
        image={require("assets/images/friends/friends-grid-image.png").default}
      />
      <How />
      <Baner />
      <File
        header="Zaproś znajomych<br /> pod ten sam adres."
        label="Dokumentacja programu poleceń"
        file={withPrefix("/dokumentacja-programu-polecen.pdf")}
        image={require("assets/images/friends/friends-file-cover.png").default}
      />
    </Layout>
  )
}

export default Friends
